@for $i from 0 through 12{
    .m-#{$i*10} { margin: 10px * $i; }
    .mt-#{$i*10} { margin-top: 10px * $i; }
    .mb-#{$i*10} { margin-bottom: 10px * $i; }
    .mr-#{$i*10} { margin-right: 10px * $i; }
    .ml-#{$i*10} { margin-left: 10px * $i; }
    .p-#{$i*10} {padding: 10px * $i;}
    .pt-#{$i*10} {padding-top: 10px * $i;}
    .pb-#{$i*10} {padding-bottom: 10px * $i;}
    .pr-#{$i*10} {padding-right: 10px * $i;}
    .pl-#{$i*10} {padding-left: 10px * $i;}
}

body{
    background-color: #fff;
}
body, h1, h2{
    color: #000;

}
.pos-fixed{
    position: fixed;
    bottom: 0px;
    padding: 10px 15px;
}
.social-list li{
    opacity: 1;
}
.social-list li a{
    color: #2477B6;
}
.social-list li a:hover i, .social-list li a:hover img{
    transform: scale(1.2);
}
.bgc--white{
    background-color: #fff;
}
p{
    font-family: 'Montserrat', sans-serif;
}
.cover .pos-bottom .social-list {
    margin-bottom: 30px;
}
.issuu-icon{
        margin-bottom: 3px;
        width: 23px;
        @media screen and (max-width: 767px){
            width: 13px;
        }
}
.text-margin{
    margin-top: 240px;
    @media screen and (max-width: 1199px){
        margin-top: 160px;
    }
    @media screen and (max-width: 767px){
        margin-top: 100px;
        margin-bottom: 120px;
    }
}
@media screen and (max-width: 767px){
    .height-100{
        height: 100vh;
    }
    h3{
        font-size: 30px;
    }
}